import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        const _this = this;
        setTimeout(function () { _this.openToast(); }, 50);
    }

    openToast() {
        const _this = this;
        _this.element.classList.add("is-open");
        setTimeout(function () { _this.closeToast(); }, 2500);
    }

    closeToast() {
        const _this = this;
        _this.element.classList.remove("is-open");
        setTimeout(function () { _this.destroyToast(); }, 300);
    }

    destroyToast() {
        this.element.remove()
    }
}