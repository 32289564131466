import { Controller } from "stimulus"
//import gsap from "gsap/all";
import Swiper from 'swiper';
import imagesLoaded from 'imagesloaded';

export default class extends Controller {
    static targets = ["normalSlider", "gallerySlider", "launchSlider"];

    connect() {
        this.swiperInstances = [];

        if (this.hasNormalSliderTarget) {
            this.blockSlider();
        }
        if (this.hasGallerySliderTarget) {
            this.blockSliderGallery();
        }
        if (this.hasLaunchSliderTarget) {
            this.blockSliderLaunch();
        }
    }

    disconnect() {
        /* Distruggo gli slider inizializzati in questo controller */
        if (this.swiperInstances.length > 0) {
            for (var i = this.swiperInstances.length - 1; i >= 0; --i) {
                this.swiperInstances[i].instance.destroy();
                this.swiperInstances.splice(i, 1);
            }
        }
    }

    blockSlider() {
        const swiperKey = "normal-slider";

        Array.from(this.normalSliderTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    resistanceRatio: 0,
                    //navigation: {
                    //    nextEl: target.closest('.slider').querySelector('.swiper-button-next'),
                    //    prevEl: target.closest('.slider').querySelector('.swiper-button-prev'),
                    //},
                    pagination: {
                        el: target.querySelector('.swiper-pagination'),
                        type: 'bullets',
                        clickable: true
                    }
                })
            });

            if (target.querySelectorAll('.swiper-slide').length <= 1) {
                target.classList.add("--noswiping");
            }
        });
    }

    blockSliderGallery() {
        const swiperKey = "gallery-slider";

        Array.from(this.gallerySliderTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    resistanceRatio: 0,
                    observer: true,
                    observeSlideChildren: true,
                    scrollbar: {
                        el: '.swiper-scrollbar',
                    },
                    //navigation: {
                    //    nextEl: target.closest('.slider').querySelector('.swiper-button-next'),
                    //    prevEl: target.closest('.slider').querySelector('.swiper-button-prev'),
                    //},
                    //pagination: {
                    //    el: target.querySelector('.swiper-pagination'),
                    //    type: 'bullets',
                    //    clickable: true
                    //}
                })
            });

            imagesLoaded(target, () => {
                setTimeout(() => {
                    this.swiperInstances.filter(x => x.key === swiperKey + index).map(x => x.instance.update());
                }, 100)
            });

        });
    }

    blockSliderLaunch() {
        const swiperKey = "launch-slider";

        Array.from(this.launchSliderTargets).forEach((target, index) => {
            this.swiperInstances.push({
                key: swiperKey + index,
                instance: new Swiper(target, {
                    slidesPerView: 1,
                    spaceBetween: 40,
                    resistanceRatio: 0,
                    //navigation: {
                    //    nextEl: target.closest('.slider').querySelector('.swiper-button-next'),
                    //    prevEl: target.closest('.slider').querySelector('.swiper-button-prev'),
                    //},
                    pagination: {
                        el: target.querySelector('.swiper-pagination'),
                        type: 'bullets',
                        clickable: true
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40
                        },
                        // when window width is >= 1025px
                        1025: {
                            slidesPerView: 3,
                            spaceBetween: 80
                        }
                    }
                })
            });


            if (window.innerWidth >= 1025 && target.querySelectorAll('.swiper-slide').length <= 3) {
                target.classList.add('--noswiping');
            }
            else if (window.innerWidth < 1025 && window.innerWidth >= 768 && target.querySelectorAll('.swiper-slide').length <= 2) {
                target.classList.add('--noswiping');
            }
            else if (window.innerWidth < 768 && target.querySelectorAll('.swiper-slide').length <= 1) {
                target.classList.add('--noswiping');
            }

        });
    }

}

