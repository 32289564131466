import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["popup"];

    doFormRewards(form) {

        //Loading
        document.body.classList.add("is-loading");

        //Controllo se email è uguale a conferma email
        if (form.querySelector('input[name="emailRewards"]').value !== form.querySelector('input[name="emailConfirmRewards"]').value) {
            this.mainController.createToast("Email e conferma email devono essere uguali");

            //Loaded
            document.body.classList.remove("is-loading");

            return true;
        }

        const dataObj = {
            email: form.querySelector('input[name="emailRewards"]').value,
            codiceFiscale: form.querySelector('input[name="fiscalCodeRewards"]').value
        };

        fetch('/' + lang + '/ajax/svc.asmx/RegistrazionePremio', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObj)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            else {
                //Error handling
                throw new Error('response not ok');
            }
        }).then((responseJson) => {
            //Loaded
            document.body.classList.remove("is-loading");

            if (responseJson.d.status) {
                this.popupTarget.classList.add("is-open");
            }
            else {
                this.mainController.createToast(responseJson.d.message);
            }
        }).catch((error) => {
            console.log(error);

            //Loaded
            document.body.classList.remove("is-loading");

            //Error handling
            this.mainController.createToast("Errore, riprova più tardi");
        });

    }

    formRewardsSubmit(e) {
        e.preventDefault();

        var form = e.currentTarget;

        this.doFormRewards(form);
    }


    /*
        Main Controller
    */

    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}