import { Controller } from "stimulus"
import gsap from "gsap/all";

export default class extends Controller {

    toggleAccordion(e) {
        e.preventDefault();

        var accordion = e.currentTarget.closest('.accordion');
        var accordionContent = accordion.querySelector('.accordion__content');

        if (accordion.classList.contains('--open')) {
            accordion.classList.remove('--open');
            gsap.to(accordionContent, 0.3, {
                height: 0
            });
        } else {
            accordion.classList.add('--open');
            gsap.to(accordionContent, 0.3, {
                height: "auto"
            });
        }
    }

}

