import { Controller } from "stimulus"
import StimulusControllerResolver from 'stimulus-controller-resolver';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


export default class extends Controller {

    /*
        Run functions ---- connect == load pagina
    */

    connect() {

        this.vh();

        window.addEventListener("resize", () => {
            this.vh();
        }, true);


        this.IEdetect();

        //covid popup 2022
        //this.covidRulesPopup = "crPopup";

        //if (document.cookie.indexOf(this.covidRulesPopup) >= 0) {
        //    //cookiesLayer.parentNode.removeChild(cookiesLayer);
        //} else {
        //    document.querySelector('.covid-rules-popup').classList.add('on');
        //}
    }


    /*
        Utils
    */

    vh() {
        const vh = window.innerHeight * 0.01;
        this.element.style.setProperty("--vh", `${vh}px`);
    }

    IEdetect() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            document.documentElement.classList.add('ie');
        }

        return false;
    }

    bodyNoScroll() {
        disableBodyScroll(this.bodyTarget);
    }

    bodyScroll() {
        enableBodyScroll(this.bodyTarget);
    }

    elementIsInView(el) {
        const scroll = window.scrollY || window.pageYOffset
        const boundsTop = el.getBoundingClientRect().top + scroll

        const viewport = {
            top: scroll,
            bottom: scroll + window.innerHeight,
        }

        const bounds = {
            top: boundsTop,
            bottom: boundsTop + el.clientHeight,
        }

        return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
            || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
    }

    //covid popup 2022

    closeCovidPopup() {
        document.querySelector('.covid-rules-popup').classList.remove('on');
        document.cookie = this.setCookie(this.covidRulesPopup, "1");
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    createToast(content) {
        const app = this.application;

        if (!document.querySelector('.toast')) {
            var toast = document.createElement("div");
            toast.classList.add("toast", "toast--error");
            toast.setAttribute("data-controller", "toast");
            toast.innerHTML = `<p>${content}</p><button aria-label="Chiudi messaggio" data-action="toast#closeToast"></button>`;
            document.body.appendChild(toast);

            StimulusControllerResolver.install(app, async () => (
                (await import(`./toast_controller.js`)).default
            ));
        }
    }
}

