import { Controller } from "stimulus"
import gsap from "gsap/all";

export default class extends Controller {

    connect() {
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        var isScrolling = false;
        var parallaxEl = this.element;

        window.addEventListener('scroll', () => {
            var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    var localScroll,
                        totalScroll,
                        actualScroll,
                        bottomWindow = st + window.innerHeight;

                    var parallaxContainerTop = parallaxEl.closest(".row").offsetTop;
                    localScroll = bottomWindow - parallaxContainerTop;
                    totalScroll = parallaxEl.offsetHeight + window.innerHeight;
                    actualScroll = (localScroll * 50) / totalScroll;

                    if (bottomWindow > parallaxContainerTop) {
                        gsap.set(parallaxEl, { yPercent: - actualScroll })
                        //parallaxEl.style.transform = `translate(0%,-${actualScroll}%)`;
                    }

                    isScrolling = false;
                })
            }
        })
    }

}

