import { Controller } from "stimulus"
import { gsap, Cubic } from "gsap/all"

export default class extends Controller {
    static targets = ["shareLayer", "shareButton", "shareOverlay"]

    connect() {

        this.overlayInit();
        this.chekcPositionOnLoad();

        //scrolltop check
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        var footer = document.getElementById('ft');

        var isScrolling = false;
        this.shareButtonTarget.classList.add('--on');
        window.addEventListener('scroll', () => {
            var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {
                    var footerIsInView = this.mainController.elementIsInView(footer);

                    if (window.innerWidth < 768) {
                        if (footerIsInView) {
                            this.shareButtonTarget.classList.add('--slide-up');
                            this.shareOverlayTarget.classList.add('--slide-up');
                        } else {
                            this.shareButtonTarget.classList.remove('--slide-up');
                            this.shareOverlayTarget.classList.remove('--slide-up');
                        }
                    }

                    isScrolling = false;
                })
            }
        })        

        //update overlay size
        window.addEventListener('resize', () => {
            this.overlaySizes();
        });
    }

    chekcPositionOnLoad() {
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        var footer = document.getElementById('ft');
        var footerIsInView = this.mainController.elementIsInView(footer);
        if (footerIsInView) {
            this.shareButtonTarget.classList.remove('--on');
        } else {
            this.shareButtonTarget.classList.remove('--on');
            if (st > 50) {
                this.shareButtonTarget.classList.add('--on');
            } else {
                this.shareButtonTarget.classList.remove('--on');
            }
        }
    }

    openShareLayer(e) {
        e.preventDefault();

        this.mainController.bodyNoScroll();
        this.shareLayerTarget.classList.add('--open');

        gsap.to(this.shareOverlayTarget.querySelector('span'), .5, {
            translateZ: 0,
            scaleX: 1,
            scaleY: 1,
            ease: Cubic.easeIn
        });
    }

    closeShareLayer(e) {
        e.preventDefault();

        this.mainController.bodyScroll();
        this.shareLayerTarget.classList.remove('--open');

        setTimeout(() => {
            gsap.to(this.shareOverlayTarget.querySelector('span'), .5, {
                translateZ: 0,
                scaleX: 0,
                scaleY: 0,
                ease: Cubic.easeIn
            });
        }, 100);
    }

    /*
        Share Overlay
     */
    overlayInit() {
        gsap.set(this.shareOverlayTarget.querySelector('span'), {
            scaleX: 0,
            scaleY: 0,
            translateZ: 0
        });

        this.overlaySizes();
    }

    overlaySizes() {
        var diameterValue = Math.sqrt(Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)) * 2;
        gsap.set(this.shareOverlayTarget.querySelector('span'), {
            height: diameterValue + 'px',
            width: diameterValue + 'px',
            top: -(diameterValue / 2) + 'px',
            left: -(diameterValue / 2) + 'px'
        });
    }

    /*
        Share
     */

    share(e) {
        e.preventDefault();

        const shareLink = location.href;
        const shareText = document.querySelector('.js-form-params input[name="shareText"]').value;
        const social = e.target;
        if (social.classList.contains('icon-facebook')) {
            window.open("https://www.facebook.com/sharer.php?u=" + encodeURIComponent(shareLink), "share", "height=440,width=900,scrollbars=true");
        } else if (social.classList.contains('icon-twitter')) {
            var twitterShare = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(shareLink);
            if (shareText.length) {
                twitterShare = twitterShare + "&text=" + encodeURIComponent(shareText);
            }
            window.open(twitterShare, "twitter", "width=600,height=400");
        } else if (social.classList.contains('icon-whatsapp')) {
            var whatsappShare = "https://api.whatsapp.com/send?text=" + encodeURIComponent(shareText) + " " + encodeURIComponent(shareLink);
            window.open(whatsappShare, "whatsapp", "width=600,height=400");
        } else if (social.classList.contains("icon-linkedin")) {
            var linkedinShare = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(shareLink);
            if (shareText.length) {
                linkedinShare = linkedinShare + "&title=" + encodeURIComponent(shareText);
            }
            window.open(linkedinShare, "linkedin", "width=600,height=400");
        }
    }

    /*
        Main Controller
    */

    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}